<script>
    import { Flex, Text } from "@axel669/zephyr"
</script>

<Flex b="1px solid @primary">
    <Text p="4px" b="1px solid @secondary">Content</Text>
    <Text p="4px" b="1px solid @secondary">More content</Text>
    <Text p="4px" b="1px solid @secondary">Event more content</Text>
</Flex>

<Flex direction="row"b="1px solid @primary">
    <Text p="4px" b="1px solid @secondary">Content</Text>
    <Text p="4px" b="1px solid @secondary">More content</Text>
    <Text p="4px" b="1px solid @secondary">Event more content</Text>
</Flex>
