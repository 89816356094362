<script>
    import { HexagonSpinner, CircleSpinner } from "@axel669/zephyr"
</script>

<HexagonSpinner size="80px" />
<HexagonSpinner color="@secondary" />
<HexagonSpinner size="120px" color="@accent" />
<HexagonSpinner size="140px" color="@warning" />
<HexagonSpinner size="160px" color="@danger" />

<div />

<CircleSpinner size="80px" />
<CircleSpinner color="@secondary" />
<CircleSpinner size="120px" color="@accent" />
<CircleSpinner size="140px" color="@warning" />
<CircleSpinner size="160px" color="@danger" />
