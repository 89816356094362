<script>
    import { Avatar } from "@axel669/zephyr"
</script>

<Avatar image="https://cdn.frankerfacez.com/avatar/twitch/64263610" />
<Avatar image="https://cdn.frankerfacez.com/avatar/twitch/186685294" alt="hachi" />
<Avatar image="https://cdn.frankerfacez.com/avatar/twitch/267212376" alt="lola" />
<Avatar image="https://axel669.net/nope.png" alt="Nope" color="@secondary" />
<Avatar text="Hi" color="@primary" />
Alongside some text
